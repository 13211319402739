import { hasNames } from "../utils";
import { graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { useState} from "react";

import InternalHero from "../components/internal-hero";
import Button from "../components/button";
import Frame from "../components/frame";
import { HeadMeta } from "../components/head-meta";
import TeamMember from "../components/team-member";
import TeamMemberModal from "../components/team-member-modal";
import QuoteCarousel from "../components/quote-carousel";
import ContactUsFooter from "../components/contact-us-footer";

import * as com from "../styles/common.module.scss";
import * as styles from "../styles/advisors.module.scss";
import * as grd from "../styles/grid.module.scss";
import * as contactFooter from "../styles/contact-us-footer.module.scss";

export const Head = () => {
	return <HeadMeta title="Wealth" />;
};

const AdvisorsPage = ({ data }) => {
	const [activeFilter, setActiveFilter] = useState("Cynosure Wealth Advisors");

	const [memberOpen, setMemberOpen] = useState(-1);

	const placements = ['Cynosure Wealth Advisors', 'Business and Operations']

	return (
		<Frame section='wealth'>
			<InternalHero
				eyebrow={"Our Team"}
				backdrop={
					<StaticImage
						src='../images/heros/cwa-hero-team.jpg'
						alt="background image"
						aria-hidden="true"
						placeholder="blurred"
						layout="fullWidth"
						loading="eager"
					/>
				}>
				<h1>Insight, access, and experience to go above and beyond.</h1>
				<p>
				We draw on our advisors’ wealth of experience from diverse professional backgrounds—including senior managerial positions at some of the world’s most reputable investment, legal, and tax firms—to provide superb service and customized solutions.
				</p>
			</InternalHero>
			<div className={hasNames(grd.container, styles.copyBlock)}>
				<div className={styles.copyBlockInner}>
					<h2>Our Team</h2>
				</div>
			</div>
			<div className={hasNames(com.filterBox)}>
				{
				placements
					.sort()
					.reverse()
					.map((placement) => {
						return (
							<Button
								type="filter"
								active={activeFilter === placement}
								onClick={() => {
									setActiveFilter(placement);
								}}>
								{placement}
							</Button>
						);
					})}
				<Button
					type="filter"
					active={activeFilter === ""}
					onClick={() => {
						setActiveFilter("");
					}}>
					Show All
				</Button>
			</div>
			{/* Show All */}
			<div className={hasNames(grd.gridContainer, styles.membersBlock)}>
				{data.showAll.nodes.map((person, index) => {
					if (
						activeFilter !== ""
					) {
						return null;
					}

					person.frontmatter.name =
						person.frontmatter.first_name + " " + person.frontmatter.last_name;
					return (
						<TeamMember
							key={"team-member-" + person.id}
							className={hasNames(grd.col3, grd.colMd4, grd.colSm6)}
							image={
								<GatsbyImage
									image={getImage(person.frontmatter.image)}
									alt={person.frontmatter.name}
								/>
							}
							name={person.frontmatter.name}
							position={person.frontmatter.title_short}
							openFn={() => {
								setMemberOpen(index);
								document.body.classList.add("scrollLocked");
							}}
							closeFn={() => {
								setMemberOpen(-1);
								document.body.classList.remove("scrollLocked");
							}}
							nextFn={
								index + 1 === data.showAll.nodes.length
									? null
									: () => {
											setMemberOpen(index + 1);
									  }
							}
							prevFn={
								index === 0
									? null
									: () => {
											setMemberOpen(index - 1);
									  }
							}
							isOpen={index === memberOpen}>
							<TeamMemberModal
								name={person.frontmatter.name}
								position={person.frontmatter.title_long}
								linkedin={person.frontmatter.linkedin}
								image={
									<GatsbyImage
										image={getImage(person.frontmatter.image)}
										alt={person.frontmatter.name}
									/>
								}>
								<div
									dangerouslySetInnerHTML={{
										__html: person.html,
									}}></div>
							</TeamMemberModal>
						</TeamMember>
					);
				})}
			</div>
			{/* Cynosure Wealth Management */}
			<div className={hasNames(grd.gridContainer, styles.membersBlock)}>
				{data.wealth.nodes.map((person, index) => {
					const groupName = (person.frontmatter.wealth)? 'Cynosure Wealth Advisors' : null
					if (
						activeFilter !== groupName
					) {
						return null
					}

					person.frontmatter.name =
						person.frontmatter.first_name + " " + person.frontmatter.last_name;
					return (
						<TeamMember
							key={"team-member-" + person.id}
							className={hasNames(grd.col3, grd.colMd4, grd.colSm6)}
							image={
								<GatsbyImage
									image={getImage(person.frontmatter.image)}
									alt={person.frontmatter.name}
								/>
							}
							name={person.frontmatter.name}
							position={person.frontmatter.title_short}
							openFn={() => {
								setMemberOpen(index);
								document.body.classList.add("scrollLocked");
							}}
							closeFn={() => {
								setMemberOpen(-1);
								document.body.classList.remove("scrollLocked");
							}}
							nextFn={
								index + 1 === data.wealth.nodes.length
									? null
									: () => {
											setMemberOpen(index + 1);
									  }
							}
							prevFn={
								index === 0
									? null
									: () => {
											setMemberOpen(index - 1);
									  }
							}
							isOpen={index === memberOpen}>
							<TeamMemberModal
								name={person.frontmatter.name}
								position={person.frontmatter.title_long}
								linkedin={person.frontmatter.linkedin}
								image={
									<GatsbyImage
										image={getImage(person.frontmatter.image)}
										alt={person.frontmatter.name}
									/>
								}>
								<div
									dangerouslySetInnerHTML={{
										__html: person.html,
									}}></div>
							</TeamMemberModal>
						</TeamMember>
					);
				})}
			</div>
			{/* Business */}
			<div className={hasNames(grd.gridContainer, styles.membersBlock)}>
				{data.business.nodes.map((person, index) => {
					const groupName = (person.frontmatter.business)? 'Business and Operations' : null
					if (
						activeFilter !== groupName
					) {
						return null
					}

					person.frontmatter.name =
						person.frontmatter.first_name + " " + person.frontmatter.last_name;
					return (
						<TeamMember
							key={"team-member-" + person.id}
							className={hasNames(grd.col3, grd.colMd4, grd.colSm6)}
							image={
								<GatsbyImage
									image={getImage(person.frontmatter.image)}
									alt={person.frontmatter.name}
								/>
							}
							name={person.frontmatter.name}
							position={person.frontmatter.title_short}
							openFn={() => {
								setMemberOpen(index);
								document.body.classList.add("scrollLocked");
							}}
							closeFn={() => {
								setMemberOpen(-1);
								document.body.classList.remove("scrollLocked");
							}}
							nextFn={
								index + 1 === data.business.nodes.length
									? null
									: () => {
											setMemberOpen(index + 1);
									  }
							}
							prevFn={
								index === 0
									? null
									: () => {
											setMemberOpen(index - 1);
									  }
							}
							isOpen={index === memberOpen}>
							<TeamMemberModal
								name={person.frontmatter.name}
								position={person.frontmatter.title_long}
								linkedin={person.frontmatter.linkedin}
								image={
									<GatsbyImage
										image={getImage(person.frontmatter.image)}
										alt={person.frontmatter.name}
									/>
								}>
								<div
									dangerouslySetInnerHTML={{
										__html: person.html,
									}}></div>
							</TeamMemberModal>
						</TeamMember>
					);
				})}
			</div>
			<ContactUsFooter
			backdrop={
				<StaticImage
					className={contactFooter.image}
					src={`../images/cwa-footer.png`}
					alt=""
					aria-hidden="true"
					layout="fullWidth"
					quality={85}
				/>
			}/>
		</Frame>
	);
};

export default AdvisorsPage;

export const query = graphql`
	query AdvisorPageQuery {
		showAll: allMarkdownRemark(
			filter: {
				fileAbsolutePath: { regex: "/src/cms/data/team/" }
			}
			sort: { fields: frontmatter___last_name }
		) {
			nodes {
				id
				frontmatter {
					first_name
					last_name
					title_long
					title_short
					linkedin
					image {
						childImageSharp {
							gatsbyImageData(
								width: 360
								height: 360
								quality: 90
								transformOptions: { cropFocus: NORTH }
							)
						}
					}
				}
				html
			}
		}
		wealth: allMarkdownRemark(
			filter: {
				fileAbsolutePath: { regex: "/src/cms/data/team/" }
				frontmatter: { wealth: { eq: true } }
			}
			sort: { fields: frontmatter___wealth_placement }
		) {
			nodes {
				id
				frontmatter {
					first_name
					last_name
					title_long
					title_short
					linkedin
					wealth
					wealth_placement
					image {
						childImageSharp {
							gatsbyImageData(
								width: 360
								height: 360
								quality: 90
								transformOptions: { cropFocus: NORTH }
							)
						}
					}
				}
				html
			}
		}
		business: allMarkdownRemark(
			filter: {
				fileAbsolutePath: { regex: "/src/cms/data/team/" }
				frontmatter: { business: { eq: true } }
			}
			sort: { fields: frontmatter___business_placement }
		) {
			nodes {
				id
				frontmatter {
					first_name
					last_name
					title_long
					title_short
					linkedin
					business
					business_placement
					image {
						childImageSharp {
							gatsbyImageData(
								width: 360
								height: 360
								quality: 90
								transformOptions: { cropFocus: NORTH }
							)
						}
					}
				}
				html
			}
		}
	}
`;
