import { hasNames } from "../utils";
import BaseModal from "./base-modal";
import * as React from "react";



import * as com from "../styles/common.module.scss";
import * as styles from "../styles/team-member.module.scss";
import * as typ from "../styles/typography.module.scss";


const TeamMember = ({
	className,
	image,
	name,
	position,
	children,
	isOpen = false,
	openFn = () => {},
	closeFn = () => {},
	nextFn = null,
	prevFn = null,
}) => {
	return (
		<>
			<button className={hasNames(styles.card, className)} onClick={openFn}>
				<div className={com.imageClippedEx}>{image}</div>
				<h4 className={styles.name}>{name}</h4>
				<div className={hasNames(styles.position, typ.eyebrow)}>{position}</div>
			</button>
			<BaseModal
				className="test"
				width={860}
				onRequestClose={closeFn}
				isOpen={isOpen}
				Content={children}
				next={nextFn}
				previous={prevFn}
			/>
		</>
	);
};

export default TeamMember;
