import { hasNames } from "../utils";
import * as React from "react";
import { forwardRef } from "react";

import HeroOverlay from "../images/hero-overlay.inline.svg";

import * as grd from "../styles/grid.module.scss";
import * as styles from "../styles/internal-hero.module.scss";

const InternalHero = forwardRef((props, ref) => {
	const { page, eyebrow, date, backdrop = null, children } = props;

	function imagePosition(page) {
		switch(page) {
			case 'approachHero':
				return styles.approachHero;
			default:
				return "";
		}
	}

	return (
		<div ref={ref} className={hasNames(styles.internalHero, imagePosition(page))}>
			<HeroOverlay className={styles.overlay} />
			{backdrop}
			{/* <div className={hasNames(styles.backdrop)}></div> */}
			<div className={hasNames(styles.childWrap, grd.gridContainer)}>
				<div className={hasNames(grd.col8, grd.colSm12)}>
					{eyebrow === undefined ? null : (
						<div className={styles.brow}>{eyebrow}</div>
					)}
					{children}
					{date === undefined ? null : (
						<div className={styles.date}>{date}</div>
					)}
				</div>
			</div>
		</div>
	);
});
export default InternalHero;
